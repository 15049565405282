<template>
  <div class="full-area">
    <QuestionHeader
      :data="data"
      :no-question-title="true"
    ></QuestionHeader>

    <p class="help-text" v-if="data.fields.helpText">{{data.fields.helpText}}</p>

    <content class="content delay-entry">
      <div
        :style="{backgroundColor: colorList[originalArray.indexOf(item)]}"
        class="list-item"
        v-for="item in items" v-dragging="{ item: item, list: items, group: 'item' }"
        :key="item"
      >{{item}}</div>
    </content>

    <SkipQuestionButton
      :disable-submit-button="disableSubmit"
      :show-submit="true"
      v-on:skip="skipQuestion()"
      v-on:submit="submitQuestion()"
    ></SkipQuestionButton>
  </div>
</template>

<script>
import chroma from 'chroma-js'
var randomColor = require('randomcolor')

export default {
  name: 'Slider',

  props: [ 'data' ],

  components: {
    QuestionHeader: () => import('Components/base/QuestionHeader'),
    SkipQuestionButton: () => import('Components/base/SkipQuestionButton')
  },

  data () {
    return {
      disableSubmit: true,
      colorList: [],
      palette: ['#FEEF73', '#F16459', '#E44180', '#9873CE', '#7785CB', '#54C3F7', '#4FCFDA', '#87C788', '#D3E15A'],
      items: this.data.fields.list.sort(function () { return 0.5 - Math.random() }),
      originalArray: this.data.fields.list.slice(),
      form: {
        question: this.data.fields.title,
        id: this.data.sys.id,
        selected: null,
        timeToCompletion: null,
        timestamps: {
          start: new Date(),
          finish: null,
          valid: null
        }
      },
      timer: null
    }
  },

  computed: {
  },

  methods: {
    skipQuestion () {
      this.form.selected = ['S99']
      this.next()
    },
    submitQuestion () {
      this.form.selected = this.items
      this.next()
    },
    next () {
      this.form.timestamps.finish = new Date()
      this.form.timestamps.valid = new Date()
      this.form.timeToCompletion = this.form.timestamps.valid.getTime() - this.form.timestamps.start.getTime()
      this.$emit('next', this.form)
    }
  },

  mounted () {
    this.colorList = randomColor({
      count: this.items.length,
      format: 'rgba',
      luminosity: 'bright',
      alpha: 0.9
    })
    this.colorList = shuffleArray(this.palette)

    this.$dragging.$on('dragend', ({ value }) => {
      this.disableSubmit = false
    })
  }
}
function shuffleArray (array) {
  if (array && array.length) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
  return array
}
</script>

<style scoped lang="scss">
  .question-header {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    max-height: 30vh;
    @include breakpoint($desktop) {
      max-height: 40vh;
      padding: 20px 10px 0 10px;
    }
  }
  .content {
    margin-bottom: 60px;
    margin-top: -60px;
    flex-direction: column;
    @include breakpoint($micro) {
      margin-top: 0;
      margin-bottom: 0;
    }
    @include breakpoint($desktop) {
      margin-top: 0;
      margin-bottom: 0;
    }
    @include breakpoint($air) {
      margin-top: -100px;
    }
  }
  .list-item {
    transition: all 0.2s ease-in-out;
    font-size: 1.4em;
    line-height: 1.5;
    background-color: #f0f0f0;
    border: 1px solid #c0c0c0;
    width: 70%;
    margin-bottom: 3px;
    border-radius: 8px;
    padding: 0 0.2em;
    color: black;

    @include breakpoint($micro) {
      font-size: 1.1em;
      line-height: 1.3;
    }
    @include breakpoint($tablet) {
      font-size: 1.2em;
      margin-bottom: 6px;
      width: 50%;
    }
    @include breakpoint($desktop) {
      font-size: 1.1em;
      line-height: 1.5;
      width: 80%;
    }
    @include breakpoint($air) {
      line-height: 1.9;
    }
    @include breakpoint($fullhd) {
      font-size: 1.3em;
    }

    &.dragging {
      background-color: #c0c0c0;
      transform: scale(1.1);
      animation: shakeIt 0.8s ease-in-out infinite;
    }
  }
  .button-wrapper {
    margin-top: 30px;
  }
</style>
